<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import axios from "axios";
    import Swal from "sweetalert2";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Add Role",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Add Role",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Role",
                        href: "/master/role",
                    },
                    {
                        text: "Add Role",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,

                // variabel options data
                optionsEselon: [],

                // variable Page
                role: null,
                slug: null,
                pilihan_eselon: null
            };
        },
        mounted() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });

            // load data eselon
            var config_ref_eselon = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-eselon",
                headers:{
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                }
            }
            axios(config_ref_eselon).then(function (response){
                var response_data_fix = response.data.data.referensi;
                self.optionsEselon = response_data_fix;
                Swal.close();
            }).catch(function (error){
                console.log(error)
                Swal.close();
            });

        },
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            StoreData() {
                let self = this;
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});

                var config_store_data = {
                    method: "post",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/role",
                    data: {
                        role: self.role,
                        slug: self.slug,
                        m_eselon_id: self.pilihan_eselon?.me_id
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };

                axios(config_store_data).then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "You will direct to page master role soon.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-role" });
                        }
                    });
                }).catch(function (error) {
                    self.axiosCatchError = error.response.data.data
                    Swal.close();
                });
            }
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">

                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{axiosCatchError}}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0]}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Role Name" label-for="formrow-nama-role-input">
                                        <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Role Name..." type="text" v-model="role"></b-form-input>
                                    </b-form-group>
                                </div>

                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Slug Role" label-for="formrow-slug-role-input">
                                        <b-form-input id="formrow-slug-role-input" placeholder="Masukkan Slug Role..." type="text" v-model="slug"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Save</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>